@use '../../static/scss/Home.scss' as global;

$color-1: #A9CCE3;
$color-2: #1A5276;
$color-3: #D0D0D0;

.slide {
    aspect-ratio: 2/3; height: 35rem;
    position: relative;
    margin: auto 0;
    
    div {
        aspect-ratio: 2/3; height: inherit; 
        box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 5px;
    }

    .slideText {
        background-position: cover; background-size: cover;
        overflow: hidden;
        position: relative;
        z-index: 1;
    }

    .slideText:hover { 
        background-image: linear-gradient(180deg, #1A5276, #000000) !important;
        cursor: pointer;
        h2, p {display: block;}
    }

    .slideChange {
        overflow: visible;
        position: absolute;
        z-index: 0;
        &:hover {
            cursor: pointer;
        
            > * {
                filter: contrast(50%);
                transition: .1s ease-in-out;
            }
            .nextSlide{transform: rotate(3.5deg);}
            .dummySlide{transform: rotate(7deg);}
        }
    }

    .slideChange > * {
        background-color: $color-3;
        position: absolute;
        width: inherit;
    }
    .nextSlide {left: 2%; transform: rotate(2.5deg); z-index: 1;}
    .dummySlide {left: 4%; transform: rotate(5deg); z-index: -1;}

    h2, p {color: $color-1; display: none; overflow: hidden; position: absolute;}

    h2 {
        font-family: futurabold;
        font-size: 6rem;
        left: 50%; bottom: -10%; transform: translate(-50%, 50%);
        opacity: .4;
        white-space: nowrap;
    }

    p {
        font-size: 1.25rem;
        left: 50%; top: 50%; transform: translate(-50%, -50%);
        line-height: 2.5rem;
        overflow: hidden;
        position: absolute;  
        width: 75%;
    }

    .mouseGlow {
        aspect-ratio: 1 / 1; height: 17.5rem;
        background: radial-gradient(circle, #FFFFFF50, #FFFFFF50);
        border-radius: 50%;
        filter: blur(50px);
        opacity: 1;
        pointer-events: none;
        position: absolute;  
        transform: translate(-50%, -50%);
        transition: opacity 0.1s;
    }

    .mouseGlow {
        z-index: 2;
    }

}

@media(max-device-width: 75rem){
    .slide {
        margin: auto;
        height: 30rem;
        position: relative;
    }
}


@media(max-device-width: 40rem) {
    .slide {
        margin: auto;
        height: 25rem;
        position: relative;

        .slideChange {
            &:hover {
                cursor: pointer;
                .nextSlide{transform: rotate(7deg);}
                .dummySlide{transform: rotate(14deg);}
            }
        }

        .nextSlide {left: 2%; transform: rotate(5deg); z-index: 1;}
        .dummySlide {left: 4%; transform: rotate(10deg); z-index: -1;} 

        h2 {
            font-size: 3rem;
            left: 50%; bottom: -7.5%; transform: translate(-50%, 50%);
        }

        p {font-size: 1rem; line-height: 2rem;}

        .mouseGlow {
            display: none;
        }
    }
}

/*Standard for scaling*/
@include global.mobile;