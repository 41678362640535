@font-face {
  font-family: futurabold;
  src: url('/static/fonts/FuturaBold.otf');
}
@font-face {
  font-family: futurabook;
  src: url('/static/fonts/FuturaBook.otf');
}

$color-1: #A9CCE3;
$color-2: #1A5276;
$color-3: #D0D0D0;

:root {
  font-size: 18px;
}

html {
  align-items: center; display: flex; justify-content: center; 
  overflow: hidden;
  margin: 0; padding: 0;
  min-height: 100%;
}

.background {
  opacity: 15%;
  position: absolute; left: 50%; top:50%; transform: translate(-50%, -50%);
  width: 60rem;
  z-index: -1;
}

h1 {font-size: 3rem; font-family: futurabold;}
h3 {font-size: 1.5rem; font-weight: 400;}
a {text-decoration: none;}

.App {
  font-family: futurabook;
  width: 60rem;

  .nav {
    text-align: center;

    > * {display: inline-block; margin: 0 .5rem;}

    p {color: $color-2; font-size: 1.25rem; line-height: 1; transform: translate(0%, 20%);}

    .social {width: 3rem; vertical-align: middle;}

    .social:hover, p:hover {filter: contrast(50%);}
  }

  .body {
    color: $color-2;
    display: grid; grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    height: 100%;

    .text {
      line-height: 1.5rem;
      margin: auto 0 auto auto;

      /** 
      li {
        margin-left: .25rem;
      }

      u {
        text-decoration: none; 
        position: relative;
      }

      u:after {
        bottom: 0; left: 0; position: absolute;
        border-bottom: .25rem solid $color-3;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 5px;
        content: '';
        transition: 0.1s;
        width: 0%;
      }

    }

    .text:hover u:after {
      width: 100%;
    }
    */
    }
  }
}

@media(max-width: 75rem){
  .App {
    width: 30rem;

    .body {
        grid-template-rows: 3fr 2fr;
        grid-template-columns: 100%;
    }
  }
}

/*Standard for scaling*/
@mixin mobile {
  @media(max-width: 75rem){
    :root {font-size: 16px;}
  }

  @media(max-height: 50rem){
    :root {font-size: 14px;}
  }

  @media(max-width: 50rem){
    :root {font-size: 12px;}
  }

  @media(max-width: 40rem){
    :root {font-size: 10px;}
  }
}

@include mobile;
